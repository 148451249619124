// @codekit-prepend "featherlight.js";
// @codekit-prepend "featherlight.gallery.js";

$(window).on('load resize', function(){
    if ($(window).width() <= 899){
        $('body').removeClass('desktop').addClass('mobile');
        $('nav#nav ul.follow').detach().appendTo('.navigation');
    } else {
        $('body').removeClass('mobile').addClass('desktop');
        $('footer#footer').detach().appendTo('section#section');
    }
});
$(document).ready(function () {
    $('#site').append('<div class="overlay hidden"></div>');
    $('#site').append('<div class="tothetop hidden"></div>');
    $('.logged-out input#user_login').attr('placeholder','E-Mail');
    $('.logged-out input#user_pass').attr('placeholder','Passwort');
    $('.logged-out input.button.button-primary').val('anmelden');
    $("nav#nav .toggle").on('touch click', function(){
        $("nav#nav .navigation,.overlay").toggleClass('hidden');
        $("nav#nav .toggle").toggleClass('active');
    });
    $(".overlay,.close-btn").on('touch click', function(){
        $("nav#nav .navigation, .overlay, .loginbox").addClass('hidden');
        $("nav#nav .toggle").removeClass('active');
        $('.popup-form').addClass('hidden');
        $('nav#nav').addClass('hidden');
        $('section#slider .nav-toggle').removeClass('active');
    });
    $("nav#nav a.login").on('touch click', function(){
        $("nav#nav .navigation, .overlay, .loginbox").toggleClass('hidden');
      });
    $('nav#nav ul.menu').find('li:has(ul)').children('a').removeAttr('href').addClass("submenu");
    $('nav#nav ul.menu li a.submenu').on('touch click', function(){
        $(this).toggleClass("active").closest('li').find('ul').toggleClass('active');
    });
    $('nav#nav .navigation ul.menu li a.submenu').append('<span></span>');
    $(".site-container").scroll(function(){
        if ($(this).scrollTop() > 120) {
            $('nav#nav,.topbar').addClass('sticky');
            $('.tothetop').removeClass('hidden');
        } else {
            $('nav#nav,.topbar').removeClass('sticky');
            $('.tothetop').addClass('hidden');
        }
    });
    $(".tothetop").on('touch click', function(){
        $('.site-container').animate({scrollTop : 0},100);
	    return false;
    });
    $(".new-item i.bx,.edit-item i.bx").on('touch click', function(){
        $('.popup-form').removeClass('hidden');
        $(".overlay").toggleClass('hidden');
    });

    $(".close-btn i.bx.bx-x").on('touch click', function(){
        $('.popup-form').addClass('hidden');
        $(".overlay").addClass('hidden');
    });

    $("section#slider .nav-toggle").on('touch click', function(){
        $('nav#nav').toggleClass('hidden');
        $(".overlay").toggleClass('hidden');
        $(this).toggleClass('active');
    });

    $('ul.gallery li a').featherlightGallery({
        previousIcon: '&#9664;',
        nextIcon: '&#9654;',
        galleryFadeIn: 100,
        galleryFadeOut: 300
      });
      $('a[href$=".jpg"], a[href$=".png"], a[href$=".gif"]').attr('data-featherlight','image');
      $('img').attr('loading','lazy');

 });